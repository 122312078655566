<template>
  <div class="Qcontainer">
    <span class="QQStyle">Q</span>
    <span class="QQText">{{ question }}</span>
  </div>
</template>

<script>
export default {
  props: {
    question: String
  }
}

</script>

<style scoped>
.Qcontainer{
  display: flex;
  align-items: flex-start;
}
.QQStyle {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background: #163257;
  opacity: 0.8;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  flex-shrink: 0;
}
.QQText {
  margin-left: 16px;
  font-size: 24px;
  font-weight: 500;
  color: #163257;
  line-height: 24px;
  word-wrap: break-word;
  word-break: normal;
}
@media only screen and (max-width: 600px) {
.QQStyle {
  width: 7vw;
  height: 7vw;
  border-radius: 50px;
  background: #163257;
  opacity: 0.8;
  font-size: 1rem;
  line-height: 7vw;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
  text-align: center;
}
.QQText {
  margin-left: 2vw;
  font-size: 1.25rem;
  font-weight: 500;
  color: #163257;
  line-height: 1.5rem;
  word-wrap: break-word;
  word-break: normal;
}
}
</style>
