<template>
  <div class="Answer-container">
    <span class="QAStyle">A</span>
    <span class="QAText" v-html="text"></span>
  </div>
</template>

<script>
export default {
  props: {
    text: String
  }
}

</script>

<style scoped>
.Answer-container {
  display: flex;
  align-items: flex-start;
}
.QAStyle {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  background: #dbb76b;
  opacity: 0.8;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 5px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.QAText {
  margin-left: 16px;
  width: 1000px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 32px;
}
@media only screen and (max-width: 600px) {
.QAStyle {
  width: 7vw;
  height: 7vw;
  border-radius: 50px;
  background: #dbb76b;
  opacity: 0.8;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.QAText {
  margin-left: 3vw;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  color: #000000;
  line-height: 1.75rem;
}
}
</style>
